* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "calibri", sans-serif;
  background-color: #f5f5f5;

  min-height: 100vh;
  margin-bottom: 5rem;
}

html {
  overflow-y: scroll;
}

.noSelect {
  user-select: none;
}

header {
  background-color: #333;
  color: white;
  padding: 1rem;
  font-weight: 400;

  position: sticky;
  z-index: 9999;
  top: 0;



  .more {
    display: none;
  }

  nav {
    display: flex;
    gap: 1rem;

    justify-content: space-between;

    ul {
      display: flex;
      gap: 1rem;
      list-style: none;
      font-size: 1.25rem;
      align-items: center;

      li {
        a {
          color: white;
          text-decoration: none;
          padding: 0.5rem;
          border-radius: 5px;
          transition: background-color 0.3s;

          &:hover {
            background-color: #555;
          }
        }
      }
    }
  }
}

/* Beheer */

.mainWrap {
  display: grid;

  grid-template-columns: 1fr 3fr 1fr;
  gap: 5rem;


  aside {
    background-color: #333;
    color: white;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: fit-content;

    section {
      display: flex;
      flex-direction: column;

      align-items: flex-start;


    

      button {
        width: 64px;
        height: 64px;
        font-size: 54px;

        margin: 2rem auto 0 auto;
        border-radius: 50px;
        border-width: 0;

        &:hover {
          background-color: gray;
          cursor: pointer;
          border: 3px solid black;
        }
      }

      p {
        margin: 0 auto;
      }



    }

    ul {
      list-style: none;
      font-size: 1.25rem;
      gap: 1rem;

      li {
        a {
          color: white;
          text-decoration: none;
          padding: 0.5rem;
          border-radius: 5px;
          transition: background-color 0.3s;

          &:hover {
            background-color: #555;
          }
        }
      }
    }
  }
}


table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;

    text-align: left;
  }

  th {
    background-color: #333;
    color: white;
  }

  tr {
    transition: background-color 0.3s;
    cursor: pointer;
    transition: transform 0.3s;

    background-color: rgb(230, 230, 230);

    &:hover {
      transform: matrix3d(1.03, 0, 0, 0, 0, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);

    }
  }

  tr:nth-child(even) {
    background-color: rgb(180, 180, 180);
  }
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: white;
    padding: 1rem;
    border-radius: 5px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    max-width: 800px;
    height: fit-content;
    min-height: 500px;


    font-size: 1rem;
    input, select, button, textarea {
      font-size: 1rem;
      font-family: "calibri", sans-serif;
    }

    img {
      border: 1px solid black;
    }

    div.right {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      justify-self: end;

      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: end;

      button {
        height: 32px;
        width: 32px;

        font-size: 24px;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;


        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        background-color: #333;
        color: white;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #555;
          color: red;
        }

        p {
          margin: 2px 0 0 1px;
        }

      }
    }

    button {
      padding: 0.5rem;
      border: none;
      border-radius: 5px;
      background-color: #333;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #555;
      }
    }

    button.save {
      width: 64px !important;
      height: 64px !important;

      &:hover {
        background-color: #0f0 !important;
      }
    }
  }
}

/* Daily-list */


.item-block, .heading {
  border: 1px solid black;
}

.heading {
  background-color: lightblue;
}

.edited {
  background-color: bisque;
}


.main-list {
  margin-bottom: 25vh;
  max-width: 800px;
  margin: 0 auto 25vh auto;
}

li {
  list-style: none;
}

.item-block {
  height: 4rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;



  button {
    height: 100%;

    background: unset;
    border: none;
    cursor: pointer;
  }



  .item-img {
    height: 100%;
    width: fit-content;
  }


  img {
    height: 100%;
    max-width: calc(4rem - 1px);
    object-fit:contain;
    border-right: 1px solid black;
  }


  label {
    width: min-content;
    height: 100%;
    display: flex;

    padding: 0.25rem 0;

    input {
      font-size: 1.25rem;
      width: 100%;
      min-width: 110px;
      height: 70%;
      align-self: center;
      justify-self: center;
  
      text-align: center;
    }
  }

  input {
    font-size: 1.25rem;
    width: 15%;
    min-width: 105px;
    height: 70%;
    align-self: center;
    justify-self: center;

    text-align: center;
    margin-right: 0.25rem;
  }

  .data {
    width: 100%;
    height: 100%;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.25rem;

    color: black;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;

    .item-order-number {
      font-size: 0.75rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .item-name {
      max-height: 2.5rem;
      overflow: hidden;
    }
  }
}

.divWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#apply {
  background-color: lightgreen;
  padding: 20px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 28px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 20px;
  margin: 100px auto;
}

#apply:hover {
  background-color: #0f0;
  color: white;
  border: 2px solid black;

  transition: background-color 0.3s;
}

#apply:active {
  background-color: #0f0;
  color: white;
  border: 2px solid black;
}

input:disabled, button:disabled {
  color: black;
  background-color: white;
}





@media screen and (max-width: 600px) {
  header {
    .logo {
      display: none;
    }


    .more {
      display: block;
    }

    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
    z-index: 1000;

    nav {
      width: 100%;
      ul {
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 1rem;
      }

      .home {
        display: none;
      }
      .to-good-to-go {
        display: none;
      }
    }
  }

  .item-block {
    input {
      width: 30%;
    }
  }


  .modal {
    .modal-content {
      display: flex;
      flex-direction: column;


      margin-bottom: 4rem;

      font-size: 1.25rem;

      input, select, button, textarea {
        font-size: 1.25rem;
      }

      span, select, .ua, input {
        display: inline-block;
      }

      input[type="checkbox"] {
        width: 24px;
        height: 24px;
      }

      div.right {
        display: flex;
        flex-direction: row;

        button {
          width: 100px !important;
          height: 64px !important;

        }

      }

    }

  }
}


.loginWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.loginForm {
  width: 300px;
  height: 200px;
  margin: 0 auto;

  font-size: 1.5rem;

  input {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;

    font-size: 1.5rem;


  }

  button {
    width: 100%;
    height: 40px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
  }
}


.homeWrap {
  width: 100%;
  display: grid;
  padding: 1rem;

  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  gap: 1rem;

  .linkWrap {
    display: flex;
    height: 150px;

    border: 1px solid black;
    border-radius: 10px;
    background-color: lightsteelblue;

    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: black;

    font-size: 2rem;


    div {
      text-align: center;
      transition: transform 0.3s;
      

      &:hover {
        cursor: pointer;
        transform: matrix3d(1.02, 0, 0, 0, 0, 1.02, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      }
    }
  }
}


.ddc {
  display: grid;
  justify-content: space-around;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  padding: 50px;
}

@media screen and (max-width: 481px) {
  .ddc {
    grid-template-columns: 1fr;
  }




  /* beheer responsive */
  .mainWrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    aside {
      min-height: unset;

      position: fixed;
      padding: 0.3rem;
      width: 100%;
    }
  }

  .mainWrap > section {
    margin-top: 10rem;
  }

  input[id="query"] {
    font-size: 1.5rem;
    width: 90%;
  }

  button.hhide {
    width: 32px !important;
    height: 32px !important;

    position: absolute;
    right: 5px;
    bottom: 0px;
    font-size: 18px !important;
  }

  p.hhide {
    display: none;
  }
}


.red {
  background-color: lightcoral !important;
}

.dark-red {
  color: red;
}

@keyframes slide-down {
  from {
      transform: translateY(-100%) translateX(-50%);
      opacity: 0;
  }
  to {
      transform: translateY(0) translateX(-50%);
      opacity: 1;
  }
}


